import * as config from '../../config'
import axios from 'axios'
import * as actionTypes from './actionTypes';

export const siteInit = () => {
    return {
        type: actionTypes.SITE_INIT,
        sites: [],
        options: null
    }
}

export const siteStart = () => {
    return {
        type: actionTypes.SITE_START
    }
}

export const siteSuccess = (sites, options) => {
    return {
        type: actionTypes.SITE_SUCCESS,
        sites: sites,
        options: options
    }
}

export const siteEdit = (site) => {
    return {
        type: actionTypes.SITE_EDIT,
        site: site
    }
}

export const siteFail = (error) => {
    return {
        type: actionTypes.SITE_FAIL,
        error: error
    }
}

export const siteList = (token) => {
    return async (dispatch) => {
        dispatch(siteStart())

        const axios_options = {
            method: 'GET',
            url: config.API_URL + config.API_SITE_PATH + 's',
            headers: {
                Authorization: 'Bearer ' + token
            },
//            data: data
        }

        try {
            const response = await axios(axios_options)
            const sites = response.data.sites;
            const options = response.data.options;
            dispatch(siteSuccess(sites, options))

        } catch (err) {
            dispatch(siteFail(err))

        }
    }
}

export const siteUpdate = (token, site) => {

    const mode = site.mode
    delete site.mode

    const site_code = site.code_orig
    delete site.code_orig

    return async (dispatch) => {
        dispatch(siteUpdateStart())

        const options = {
            method: mode === 'edit' ? 'PUT' : 'POST',
            url: config.API_URL + config.API_SITE_PATH + '/' + (mode === 'edit' ? site_code : ''),
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: site
        }

        try {
            const response = await axios(options)
            const site = response.data.site;
            dispatch(siteUpdateSuccess(site))
            dispatch(siteList(token))

        } catch (err) {
            dispatch(siteUpdateFail(err))

        }
    }
}

export const siteUpdateStart = (site) => {
    return {
        type: actionTypes.SITE_UPDATE_START,
        site: site
    }
}

export const siteUpdateSuccess = (site) => {
    return {
        type: actionTypes.SITE_UPDATE_SUCCESS,
        site: site
    }
}

export const siteUpdateFail = (error) => {
    return {
        type: actionTypes.SITE_UPDATE_FAIL,
        error: error
    }
}

export const siteDbDelete = (token, site) => {

    const site_code = site.code_orig
 
    return async (dispatch) => {
        dispatch(siteDbDeleteStart())

        const options = {
            method: 'GET',
            url: config.API_URL + config.API_SITE_PATH + '-delete/' +  site_code,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: site
        }

        try {
            const response = await axios(options)
            dispatch(siteDbDeleteSuccess(response.data.message))

        } catch (err) {
            dispatch(siteDbDeleteFail(err))

        }
    }
}

export const siteDbDeleteStart = () => {
    return {
        type: actionTypes.SITE_DB_DELETE_START
    }
}

export const siteDbDeleteSuccess = (message) => {
    return {
        type: actionTypes.SITE_DB_DELETE_SUCCESS,
        message: message
    }
}

export const siteDbDeleteFail = (error) => {
    return {
        type: actionTypes.SITE_DB_DELETE_FAIL,
        error: error
    }
}

export const siteDbRestore = (token, site) => {

    const site_code = site.code_orig
 
    return async (dispatch) => {
        dispatch(siteDbRestoreStart())

        const options = {
            method: 'GET',
            url: config.API_URL + config.API_SITE_PATH + '-restore/' +  site_code,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: site
        }

        try {
            const response = await axios(options)
            dispatch(siteDbRestoreSuccess(response.data.message))

        } catch (err) {
            dispatch(siteDbRestoreFail(err))

        }
    }
}

export const siteDbRestoreStart = () => {
    return {
        type: actionTypes.SITE_DB_RESTORE_START
    }
}

export const siteDbRestoreSuccess = (message) => {
    return {
        type: actionTypes.SITE_DB_RESTORE_SUCCESS,
        message: message
    }
}

export const siteDbRestoreFail = (error) => {
    return {
        type: actionTypes.SITE_DB_RESTORE_FAIL,
        error: error
    }
}
