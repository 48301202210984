import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
    sites: [],
    site: null,
    error: null,
    loading: false,
    editing: false,
    message: '',
    deleting_db: false,
    restoring_db: false,
    options: null,
}

const siteInit = ( state, action ) => {
    return updateObject( state, initialState ) 
}
    
const siteStart = ( state, action ) => {
//    return updateObject( state, { loading: true } )

    return updateObject( state, { 
        sites: [],
        options: null,
        error: null,
        loading: true
    })

}

const siteSuccess = ( state, action ) => {
    return updateObject( state, {
        sites: action.sites,
        options: action.options,
        loading: false
    } )
}

const siteFail = ( state, action ) => {
    return updateObject( state, { 
        sites: [],
        options: null,
        error: action.error,
        loading: false
    })
}

const siteEdit = ( state, action ) => {
    return updateObject( state, { site: action.site, error: null, editing: true } )
}

const siteUpdateStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } )
}

const siteUpdateSuccess = ( state, action ) => {
    return updateObject( state, {
        site: action.site,
        loading: false,
        editing: false
    } )
}

const siteUpdateFail = ( state, action ) => {
    return updateObject( state, { 
        error: action.error,
        loading: false
    })
}

const siteDbDeleteStart = ( state, action ) => {
    return updateObject( state, { error: null, deleting_db: true } )
}

const siteDbDeleteSuccess = ( state, action ) => {
    return updateObject( state, { deleting_db: false } )
}

const siteDbDeleteFail = ( state, action ) => {
    return updateObject( state, { 
        error: action.error,
        deleting_db: false 
    })
}

const siteDbRestoreStart = ( state, action ) => {
    return updateObject( state, { error: null, restoring_db: true } )
}

const siteDbRestoreSuccess = ( state, action ) => {
    return updateObject( state, { restoring_db: false } )
}

const siteDbRestoreFail = ( state, action ) => {
    return updateObject( state, { 
        error: action.error,
        restoring_db: false 
    })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SITE_INIT: return siteInit( state, action );
        case actionTypes.SITE_START: return siteStart( state, action );
        case actionTypes.SITE_SUCCESS: return siteSuccess( state, action );
        case actionTypes.SITE_FAIL: return siteFail( state, action );
        case actionTypes.SITE_EDIT: return siteEdit( state, action );
        case actionTypes.SITE_UPDATE_START: return siteUpdateStart( state, action );
        case actionTypes.SITE_UPDATE_SUCCESS: return siteUpdateSuccess( state, action );
        case actionTypes.SITE_UPDATE_FAIL: return siteUpdateFail( state, action );
        case actionTypes.SITE_DB_DELETE_START: return siteDbDeleteStart( state, action );
        case actionTypes.SITE_DB_DELETE_SUCCESS: return siteDbDeleteSuccess( state, action );
        case actionTypes.SITE_DB_DELETE_FAIL: return siteDbDeleteFail( state, action );
        case actionTypes.SITE_DB_RESTORE_START: return siteDbRestoreStart( state, action );
        case actionTypes.SITE_DB_RESTORE_SUCCESS: return siteDbRestoreSuccess( state, action );
        case actionTypes.SITE_DB_RESTORE_FAIL: return siteDbRestoreFail( state, action );
        default: return state;
    }
};

export default reducer;