import * as config from '../../config'
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { getNested } from '../../shared/utility';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId, name, siteCode, siteId, siteUserId, expiresIn) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        name: name,
        siteCode: siteCode,
        siteId: siteId,
        siteUserId: siteUserId,
        expiresIn: expiresIn
    };
};

export const authFail = (error, message) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
        message: message
    };
};

export const logout = () => {

    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime);
    };
};

export const auth = (email, password, dateOfBirth, pinCode, resetCode, authState) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            dateOfBirth: dateOfBirth,
            pinCode: pinCode,
            resetCode: resetCode,
            returnSecureToken: true
        };

        let method = 'post'
        let url = config.API_URL + config.API_AUTHORIZATION_PATH + '/' + authState
        if (authState === 'register' ||
            authState === 'reset' ) {
            method = 'put'
        }

        axios({
                method: method,
                url: url, 
                data: authData
            })
            .then(response => {
                //const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.email);
                localStorage.setItem('name', response.data.name);
                localStorage.setItem('siteCode', response.data.siteCode);
                localStorage.setItem('siteId', response.data.siteId);
                localStorage.setItem('siteUserId', response.data.siteUserId);
                localStorage.setItem('expiresIn', response.data.expiresIn);

                const message = response.data.msg !== undefined ? response.data.msg : ''

                dispatch(authSuccess(response.data.token, response.data.email, response.data.name, 
                    response.data.siteCode, response.data.siteId, response.data.siteUserId, response.data.expiresIn, message));
                dispatch(checkAuthTimeout(response.data.expiresIn));
            })
            .catch((err) => {
                let message = getNested(err, 'response', 'data', 'data', '0', 'msg')
                if(message === undefined) {
                    message =  getNested(err, 'response', 'data', 'message')
                    if(message === undefined) {
                        message = ''
                    }
                }

                dispatch(authFail(err, message));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const setAuthState = (authState) => {
    return {
        type: actionTypes.SET_AUTH_STATE,
        authState: authState
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                const name = localStorage.getItem('name');
                const siteCode = localStorage.getItem('siteCode');
                const siteId = localStorage.getItem('siteId');
                const siteUserId = localStorage.getItem('siteUserId');
                const expiresIn = localStorage.getItem('expiresIn');
                dispatch(authSuccess(token, userId, name, siteCode, siteId, siteUserId, expiresIn));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }   
        }
    };
};