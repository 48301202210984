import React, { useEffect, Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.css';

import Spinner from 'react-bootstrap/Spinner';

import Layout from './hoc/Layout/Layout';

import Logout from './containers/Auth/Logout/Logout';
import * as actions from './store/actions/index';

// const Home = () => <span>Home</span>;

const Auth = React.lazy(() => {
  return import('./containers/Auth/Auth');
});

const Site = React.lazy(() => {
  return import('./containers/Site/Site');
});

const openPost = function (url, params) {
  let formElement = document.createElement('form')
  formElement.setAttribute('method', 'post')
  formElement.setAttribute('action', url)
  formElement.setAttribute('target', '_parent')

  for (let param in params) {
      let hiddenField = document.createElement('input')
      hiddenField.setAttribute('name', param)
      hiddenField.setAttribute('value', params[param])
      hiddenField.setAttribute('hidden', '')
      formElement.appendChild(hiddenField)
  }

  document.body.appendChild(formElement)
  formElement.submit();
}

const App = props => {
  const { onTryAutoSignup, auth } = props;

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  let routes = (
    <Switch>
      <Route path="/signin" render={props => <Auth {...props} />} />
      <Redirect to= {{ 
                      "pathname": "/signin",
                      "search": props.location.search
                    }} />
    </Switch>
  );

  if (props.isAuthenticated) {

    const search = props.location.search.substring(1)
    let redirect = null
    console.log('auth: ' + search)
    let parse = search.split('&')
    console.log(parse)
    parse.forEach((v) => {
      const parms = v.split('=')
      console.log(parms)
      if(parms[0] === 'redirect') {
        redirect = parms[1]       

      }
    })
    console.log('redirect: ' + redirect)

    if(redirect) {

      // console.log(props)
      let params = []                            
      params["token"] = auth.token
      params["email"] = auth.userId
      params["name"] = auth.name
      params["siteCode"] = auth.siteCode
      params["siteId"] = auth.siteId
      params["siteUserId"] = auth.siteUserId
      params["expiresIn"] = auth.expiresIn
      openPost(redirect, params)
      //window.location.href = redirect

      actions.logout()
      return ('')

    } else {
      routes = (
        <Switch>
        {/* <Route path="/" exact render={props => <Site {...props} />} /> */}
        <Route path="/site" render={props => <Site {...props} />} />
        <Route path="/signout" component={Logout} />
        <Redirect to="/site" />
        </Switch>
      );
    }
  }

  let spinner = (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status" className="m-5">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
    )


  return (
    <div>
      <Layout>
        <Suspense fallback={spinner}>{routes}</Suspense>
      </Layout>
    </div>
  );
 };

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    site: state.site,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
