export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';

export const SITE_ADD = 'SITE_ADD';
export const SITE_UPDATE = 'SITE_UPDATE';
export const SITE_UPDATE_START = 'SITE_UPDATE_START';
export const SITE_UPDATE_SUCCESS = 'SITE_UPDATE_SUCCESS';
export const SITE_UPDATE_FAIL = 'SITE_UPDATE_FAIL';
export const SITE_DELETE = 'SITE_DELETE';
export const SITE_GET = 'SITE_GET';
export const SITE_INIT = 'SITE_INIT';
export const SITE_LIST = 'SITE_LIST';
export const SITE_START = 'SITE_START';
export const SITE_SUCCESS = 'SITE_SUCCESS';
export const SITE_FAIL = 'SITE_FAIL';
export const SITE_EDIT = 'SITE_EDIT';
export const SITE_DB_DELETE = 'SITE_DB_DELETE';
export const SITE_DB_DELETE_START = 'SITE_DB_DELETE_START';
export const SITE_DB_DELETE_SUCCESS = 'SITE_DB_DELETE_SUCCESS';
export const SITE_DB_DELETE_FAIL = 'SITE_DB_DELETE_FAIL';
export const SITE_DB_RESTORE = 'SITE_DB_RESTORE';
export const SITE_DB_RESTORE_START = 'SITE_DB_RESTORE_START';
export const SITE_DB_RESTORE_SUCCESS = 'SITE_DB_RESTORE_SUCCESS';
export const SITE_DB_RESTORE_FAIL = 'SITE_DB_RESTORE_FAIL';

