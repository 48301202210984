import * as config from '../../config'

import React, { /* useState */ } from 'react';
import { connect } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
//import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';

//import classes from './Layout.css';

const layout = props => {
/*   const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);

  const sideDrawerClosedHandler = () => {
    setSideDrawerIsVisible(false);
  };

  const sideDrawerToggleHandler = () => {
    setSideDrawerIsVisible(!sideDrawerIsVisible);
  };
 */
  //console.log('Layout.props', props)
  let markup = (
    <Container fluid>
      {props.children}
    </Container>
  )
  
  if (props.isAuthenticated) {
    markup = (
      // <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
      <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">{config.APP_NAME}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
{/*             <LinkContainer to="/site">
              <Nav.Link>Site</Nav.Link>
            </LinkContainer>
 */}          </Nav>
          <Form inline>
            {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}
            <LinkContainer to="/signout" activeClassName="">
              <Button variant="outline-success">Sign out</Button>
            </LinkContainer>
          </Form>
        </Navbar.Collapse>
      </Navbar>
      <Container fluid>
          {props.children}
      </Container>
      </div>
    )
  }

  return (
    <div>
      {markup}
    </div>
  )
/*   return (
    <Container className="p-3">
      <Jumbotron>
        <h1 className="header">Welcome To React-Bootstrap</h1>
        <h2>
          Current Page is{' '}
          {props.children}
        </h2>
        <h2>
          Navigate to{' '}
          <ButtonToolbar className={classes.customBtnToolbar}>
            <LinkContainer to="/">
              <Button className={classes.customBtn}>Home</Button>
            </LinkContainer>
            <LinkContainer to="/auth">
              <Button className={classes.customBtn}>Signin</Button>
            </LinkContainer>
          </ButtonToolbar>
        </h2>
      </Jumbotron>
    </Container>
  )
 */};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

export default connect(mapStateToProps)(layout);
