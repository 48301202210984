// app config
export const APP_NAME = 'Exerbotics::Admin'

// api config
//export const API_URL = 'http://vagrant:8080'
export const API_URL = 'https://api.exerbotics.com'
export const API_AUTHORIZATION_PATH = '/auth'
export const API_SITE_PATH = '/api/site'
export const API_ADMIN_PATH = '/admin'

