import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    userId: null,
    siteCode: null,
    siteId: null,
    siteUserId: null,
    expiresIn: null,
    error: null,
    message: '',
    loading: false,
    authRedirectPath: '/',
    authState: 'login'
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, message: '', loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        name: action.name,
        siteCode: action.siteCode,
        siteId: action.siteId,
        siteUserId: action.siteUserId,
        expiresIn: action.expiresIn,
        error: null,
        message: action.message,
        loading: false,
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        message: action.message,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const setAuthState = (state, action) => {
    return updateObject(state, { authState: action.authState })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.SET_AUTH_STATE: return setAuthState(state, action);
        default:
            return state;
    }
};

export default reducer;